import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useModalResize } from 'src/hooks/useModalResize'
import Modal from 'src/components/UI/Modal/Modal'
import PaymentProcessorModal from '../PaymentProcessorModal/PaymentProcessorModal'
import PriceListShared from 'training-lane-booking-shared/components/Purchase/PriceListShared/PriceListShared'
import PriceListItem from 'src/components/Purchase/PriceListItem/PriceListItem'
import PricesSpecial from 'src/components/Purchase/PricesSpecial/PricesSpecial'
import textConstants from 'training-lane-booking-shared/utilities/text'
import {
    getType,
    getYearMonthDate,
    isSpecialPricePeriod
} from 'training-lane-booking-shared/utilities/functions'
import purchaseModalClasses from './PurchaseModal.module.scss'

const PurchaseModal = ({
    now,
    roleId,
    credits,
    lastClickedSlot,
    prices,
    pricesSpecial,
    modalStatePurchase,
    setModalState,
    updateSelectedItem
}) => {
    const [height, setHeight] = useState(0)
    const [pricesSpecialHeight, setPricesSpecialHeight] = useState(0)

    const initHeight = useModalResize(200, 200)

    const setHeightFromPricesSpecial = pricesSpecialHeight => {
        setPricesSpecialHeight(pricesSpecialHeight)
    }

    useEffect(() => {
        setHeight(initHeight - pricesSpecialHeight)
    }, [initHeight, pricesSpecialHeight])

    const foundSpecialPrice = isSpecialPricePeriod(roleId, getYearMonthDate(now), pricesSpecial)

    const handleBuyClick = itemData => {
        updateSelectedItem(itemData)
        setModalState('paymentProcessor', { state: true })
    }

    const priceListSharedProps = {
        PriceListItem,
        roleId,
        prices,
        credits,
        handleBuyClick
    }

    const selectedType = getType(lastClickedSlot.type)

    // noinspection JSUnresolvedVariable,JSValidateTypes
    return (
        <Modal name="purchase" handleClose={() => setModalState('paymentProcessor', { state: false })}>
            <div className={purchaseModalClasses.purchaseModal}>
                <>
                    <PaymentProcessorModal />
                    {modalStatePurchase.props?.calledBy === 'menu' ? (
                        <div className="align-center">
                            <div>{textConstants.PURCHASE_CREDITS}</div>
                        </div>
                    ) : (
                        <>
                            <div className="align-center">
                                <div>{textConstants.PURCHASE_NO_CREDITS}</div>
                                <div
                                    style={{
                                        color: selectedType?.color,
                                        fontWeight: 'bold'
                                    }}>
                                    {selectedType?.description.people &&
                                    `${selectedType?.description.people} `}
                                    {selectedType?.description.categoryAlt ??
                                    selectedType?.description.category}
                                </div>
                            </div>
                            <div className="align-center">{textConstants.PURCHASE_BUY_MORE}</div>
                        </>
                        )}
                    {
                        foundSpecialPrice ? (
                            <PricesSpecial
                                {...foundSpecialPrice}
                                setPurchaseModalHeight={setHeightFromPricesSpecial} />
                        ) : null
                    }
                    <div className={purchaseModalClasses.scrollable} style={{ height }}>
                        <PriceListShared {...priceListSharedProps} />
                    </div>
                </>
            </div>
        </Modal>
    )
}

const mapState = state => ({
    now: state.utils.now,
    roleId: state.firebase.profile.roleId,
    credits: state.firebase.profile.credits,
    lastClickedSlot: state.slotPicker.lastClickedSlot,
    prices: state.firestore.data.prices,
    pricesSpecial: state.firestore.ordered.pricesSpecial,
    modalStatePurchase: state.utils.modalState.purchase
})

const mapDispatch = dispatch => ({
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload),
    updateSelectedItem: item => dispatch.payments.updateSelectedItem(item)
})

export default connect(mapState, mapDispatch)(PurchaseModal)
