import React from 'react'
import { connect } from 'react-redux'
import { useModalResize } from 'src/hooks/useModalResize'
import Modal from 'src/components/UI/Modal/Modal'
import UnpaidList from 'src/components/UnpaidList/UnpaidList'
import PriceListModalShared from 'training-lane-booking-shared/components/Modals/PriceListModalShared/PriceListModalShared'
import Confirm from 'src/components/UI/Confirm/Confirm'
import BundleListItem from 'src/components/Purchase/BundleListItem/BundleListItem'
import PriceListItem from 'src/components/Purchase/PriceListItem/PriceListItem'
import PriceListWrapper from 'src/components/Purchase/PriceListWrapper/PriceListWrapper'
import textConstants from 'training-lane-booking-shared/utilities/text'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import unpaidModalClasses from './UnpaidModal.module.scss'

const UnpaidModal = ({
    roleId,
    prices,
    credits,
    nonRefundableCredits,
    groupedCredits,
    setModalState
}) => {
    const height = useModalResize()
    
    const handleOpenPriceList = () => {
        setModalState('priceList', { state: true })
    }
    
    const priceListModalSharedProps = {
        Modal,
        BundleListItem,
        PriceListItem,
        PriceListWrapper,
        roleId,
        prices,
        credits
    }

    const unpaidProps = {
        roleId,
        prices,
        credits,
        nonRefundableCredits,
        groupedCredits
    }
    
    return (
        <Modal
            name='unpaid'>
            <PriceListModalShared { ...priceListModalSharedProps } />
            <div className={unpaidModalClasses.unpaidModalWrapper} style={{ maxHeight: height }}>
                <UnpaidList { ...unpaidProps } />
                <Confirm
                    buttons={
                        [
                            {
                                buttonClasses: confirmClasses.confirmButtonProceedSafe,
                                handleFn: handleOpenPriceList,
                                label: textConstants.SHOW_PRICE_LIST
                            }
                        ]
                    } />
            </div>
        </Modal>
    )
}

const mapState = state => ({
    roleId: state.firebase.profile.roleId,
    prices: state.firestore.data.prices,
    credits: state.firebase.profile.credits,
    nonRefundableCredits: state.firebase.profile.nonRefundableCredits,
    groupedCredits: state.firebase.profile.groupedCredits
})

const mapDispatch = dispatch => ({
    setModalState: (modalName, payload) => dispatch.utils.setModalState(modalName, payload)
})

export default connect(mapState, mapDispatch)(UnpaidModal)
