import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import PurchasePickedTitle from '../PurchasePickedTitle/PurchasePickedTitle'
import Discount from '../Discount/Discount'
import BundleListItem from '../BundleListItem/BundleListItem'
import CounterContainer from 'src/containers/CounterContainer/CounterContainer'
import CounterContainerShared from 'training-lane-booking-shared/containers/CounterContainerShared/CounterContainerShared'
import { getTypeId } from 'training-lane-booking-shared/utilities/functions'
import { roles } from 'training-lane-booking-shared/utilities/constants'
import purchaseModalClasses from 'src/components/Modals/PurchaseModal/PurchaseModal.module.scss'

const PriceListItem = ({ priceList, roleId = roles.member.id }) => {
    const tlcRef = useRef(null)
    const irRef = useRef(null)
    
    // noinspection JSUnresolvedVariable
    const targetType = useSelector(
        state => state.utils.modalState.purchase.props?.targetType
    )
    
    useEffect(() => {
        if (!isEmpty(targetType)) {
            const scrollProps = {
                block: 'start',
                inline: 'center',
                behavior: 'smooth',
                alignToTop: false
            }

            if (targetType.id === getTypeId('tlc')) {
                setTimeout(() => {
                    tlcRef.current?.scrollIntoView(scrollProps)
                }, 0)
            }

            if (targetType.id !== 'tlc' && targetType.category === 'ir') {
                setTimeout(() => {
                    irRef.current?.scrollIntoView(scrollProps)
                }, 0)
            }
        }
    }, [targetType])
    
    return (
        <>
            {
                priceList.map(({ typeId, itemType, credits, bundleList }) => {
                    let wrapperProps = {
                        key: typeId
                    }
                    
                    if (typeId === 'tlc') {
                        wrapperProps.ref = tlcRef
                    }
                    
                    if (typeId === 'ir1') {
                        wrapperProps.ref = irRef
                    }
                    
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <div { ...wrapperProps }>
                            <div className={classNames('grid-wrapper', purchaseModalClasses.fullWidth)}>
                                <div className="col-10 align-left">
                                    <PurchasePickedTitle itemType={itemType} />
                                </div>
                                <div className="col-2 align-right">
                                    <CounterContainerShared
                                        onList
                                        CounterContainer={CounterContainer}
                                        credits={credits}
                                        type={itemType}
                                    />
                                </div>
                            </div>
                            {
                                itemType.description.examples && <div className={purchaseModalClasses.examples}>examples: {itemType.description.examples}</div>
                            }
                            {
                                itemType.id === getTypeId('tlc') && <div className={purchaseModalClasses.examples}>{itemType.description.categoryAlt}</div>
                            }
                            {bundleList.map(bl => (
                                // The key prop is part of each bundleList item!
                                // eslint-disable-next-line react/jsx-key
                                <BundleListItem {...bl} />
                            ))}
                            <br />
                        </div>
                    )
                })
            }
            {
                roleId === roles.ambassador.id && <Discount />
            }
        </>
    )
}

export default PriceListItem