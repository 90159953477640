import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Modal from 'src/components/UI/Modal/Modal'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import textConstants from 'training-lane-booking-shared/utilities/text'
import { paymentProcessorInterimTemplate } from 'training-lane-booking-shared/utilities/templates'
import paymentProcessorClasses from './PaymentProcessorModal.module.scss'

const PaymentProcessorModal = () => {
    const selectedItem = useSelector(state => state.payments.selectedItem)

    const dispatch = useDispatch()

    const setModalState = (modalName, payload) => dispatch.utils.setModalState(modalName, payload)

    let templateParams = ['', '', '']

    if (!isEmpty(selectedItem?.itemType) && !isEmpty(selectedItem?.bundle) && selectedItem?.total) {
        templateParams = [
            `${selectedItem?.itemType?.description?.people} ${selectedItem?.itemType?.description?.category} (${selectedItem?.itemType?.id.toUpperCase()})`,
            selectedItem?.bundle?.key,
            selectedItem?.total
        ]
    }

    const sophieSEmail = 'sophie@traininglane.fit'

    return (
        <Modal name="paymentProcessor" nested>
            <Confirm
                heading={textConstants.PAYMENT_PROCESSOR_INTERIM_HEADING}
                buttons={[
                    {
                        buttonClasses: confirmClasses.confirmButtonAbort,
                        handleFn: () => setModalState('paymentProcessor', { state: false }),
                        label: textConstants.ABORT
                    },
                    {
                        buttonClasses: confirmClasses.confirmButtonProceedDanger,
                        label: <a
                            href={[
                                'mailto:',
                                sophieSEmail,
                                '?subject=Purchase%20Request',
                                '&body=',
                                encodeURIComponent(paymentProcessorInterimTemplate(...templateParams))
                            ].join('')}
                            target="_blank"
                            rel="noopener noreferrer">{`${textConstants.SEND} ${textConstants.EMAIL}`}</a>,
                        takeEnter: true
                    }
                ]}
            />
            <div className={paymentProcessorClasses.paymentRequestInterimBottom}>
                {textConstants.PAYMENT_PROCESSOR_INTERIM_BOTTOM} {sophieSEmail}
            </div>
        </Modal>
    )
}

export default PaymentProcessorModal
